import React from "react";
import Layout from "../components/Layout";

const Index = () => {
  return (
    <Layout>
      <div className="privacy">
        <h2>Privacy Policy</h2>
        <p>
          We are committed to protecting your privacy. That is because we base
          our business on trust you place in us. This policy describes our
          practices regarding personal and account information collected through
          our website.
          <br />
          <br />
          We will periodically review the appropriateness of this privacy policy
          and may make, in our discretion, such changes as are necessary.
          <br />
          <br /> If we decide to change this privacy policy, we will post those
          changes here so that you will always know what information we gather,
          how we might use that information, and whether we will disclose it to
          anyone. You should visit our website periodically to review any
          changes.
        </p>
        <h3>Do we use the secret data for any other purpose?</h3>
        <p>
          No. We currently do not analyze or look at the secrets in any manual
          or automated way. Actually, all of your notes are encrypted before
          storing them on our database, so not even us can spying on them.
        </p>
        <h3>How safe is this tool?</h3>
        <p>
          We feel that this tool is definitely safer than using email or chat
          tools to send private information. All data flows over secure
          connections and data are encrypted already.
        </p>
        <h3>What measures do we use to protect your personal information?</h3>
        <p>
          We use secure, encrypted mechanisms (SSL) when accepting and
          transmitting information.
        </p>
        <h3>Disclaimer</h3>
        <p>
          When a person clicks the OTM's link, OTM declines any
          responsibility related to the note's content.
        </p>
        <h3>Disclosure of Data to Third Party</h3>
        <p>
          OTM does not share or sell any information to others, nor use
          it in any way not mentioned in this Privacy Policy.
        </p>
      </div>
    </Layout>
  )
}

export default Index
